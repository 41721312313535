// import { makeStyles } from '@material-ui/core/styles';

// const styles = makeStyles((theme) => ({
//   sFormHolder: {
//     display: 'grid',
//     gridTemplateColumns: '1fr auto',
//     width: '120%',
//     marginLeft: '-1rem',
//     [theme.breakpoints.only('sm')]: {
//       display: 'grid',
//       gridTemplateColumns: '1fr auto',
//       width: '120%',
//       marginLeft: '0',
//     },
//     [theme.breakpoints.only('xs')]: {
//       width: '115%',
//     },
//   },
//   formHolder: {
//     display: 'grid',
//     gridTemplateColumns: '1fr auto',
//     width: '60%',
//     [theme.breakpoints.only('sm')]: {
//       display: 'grid',
//       gridTemplateColumns: '1fr auto',
//       width: '50%',
//     },
//     [theme.breakpoints.only('xs')]: {
//       width: '100%',
//     },
//   },
//   noOutline: {
//     outline: 'none',
//   },
//   thanksIcon: {
//     height: '2.5rem',
//     marginBottom: ' 0.5rem',
//   },
//   alignText: {
//     textAlign: 'center',
//   },
// }));

// export default styles;
import { styled } from '@mui/material/styles';
//  EMAIL styles
export const NotifyButton = styled('button')(({ theme }) => ({
  outline: 'none',
  padding: '15px',
  color: ' #ffff',
  fontWeight: '700',
  backgroundColor: theme.colors.primary,
  border: `1px solid ${theme.colors.primary}`,
  borderRadius: '0px 5px 5px 0px',
}));
export const Input = styled('input')(({ theme }) => ({
  padding: '15px',
  border: ` 1.5px solid ${theme.colors.tertiary}`,
  borderRadius: '5px 0px 0px 5px',
  borderRight: 0,
}));
// Dilaogbox styles

export const DialogContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#1d252d',
  fontSize: '14px',
  margin: '3rem',
  '@media (max-width: 768px)': {
    margin: '2rem',
  },
}));

export const DialogOk = styled('div')(() => ({
  backgroundColor: 'black',
  border: '0px',
  color: 'white',
  padding: '10px 52px',
  borderRadius: '5px',
  marginTop: '12px',
}));

export const MessageTitle = styled('b')(() => ({
  textAlign: 'center',
  padding: '10px',
  textTransform: 'uppercase',
}));
