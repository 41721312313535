import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { Grid, Container } from '@mui/material';
import { Alert, AlertTitle } from '@mui/lab';
import { ENDPOINT_URL, PutRequest } from '../../utils/globalConfig';
import butlogo from '../../assets/images/butlogo.png';

export const ExclusiveInvitationEmailVerify = () => {
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState({});
  let { search } = useLocation();
  const { id } = queryString.parse(search);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await PutRequest(ENDPOINT_URL.EXCLUSIVE_VERIFY, { id: id });
        if (!!res && !!res.data) {
          setData(res.data);
        }
      } catch (err) {
        setErrors(err.data);
      }
    }
    fetchData();
  }, [id]);

  return (
    <Grid item lg={12} direction="row" container>
      <Container>
        <Grid item lg={12}>
          <div className="email-verify">
            <div className="logo">
              <img src={butlogo} alt="icon" />
            </div>

            {!!data && data.success && (
              <Alert severity="success">
                <AlertTitle className="title">Success</AlertTitle>
                <p>{data.message}</p>
              </Alert>
            )}

            {!!hasError && !hasError.success && (
              <Alert severity="error">
                <AlertTitle className="title">Error</AlertTitle>
                <p>{hasError.error_message}</p>
              </Alert>
            )}
          </div>
        </Grid>
      </Container>
    </Grid>
  );
};
