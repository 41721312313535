import React from 'react';

export const MenuPopover = ({ lang: { name, img }, children }) => {
  return (
    <nav>
      <ul className="navs">
        <li className="dropdown">
          <div
            type="button"
            className="link-title dropdown__title"
            aria-expanded="false"
            aria-controls="sweets-dropdown"
          >
            <img src={img} alt={name} /> {name}
          </div>
          <ul className="dropdown__menu top-menu">{children}</ul>
        </li>
      </ul>
    </nav>
  );
};
