import en from './default.json';
import fr from './default.fr.json';
import es from './default.es.json'
import se from './default.se.json'
import tr from './default.tr.json'
import nl from './default.nl.json'
import no from './default.no.json'
import lu from './default.lu.json'
import it from './default.it.json'
import is from './default.is.json'
import el from './default.el.json'
import de from './default.de.json'
import fi from './default.fi.json'
import dk from './default.dk.json'

export default {
  fr,
  en,
  es,
  se,
  tr,
  nl,
  no,
  lu,
  it,
  is,
  el,
  de,
  fi,
  dk
};