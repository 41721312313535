import React from 'react';
import { Grid, Container } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FooterTitle, SocialLink } from './styles';

const Footer = (props) => {
  const iconStyle = { fontSize: 48, color: '#feb63e' };
  return (
    <Grid item lg={12} className="footer">
      <Container>
        <Grid item lg={12} direction="row" justifyContent={'center'} container>
          <Grid item lg={12} className="footer-text">
            <div>{props.t('footer1')}</div>
            <div>{props.t('footer2')}</div>
          </Grid>

          <Grid
            item
            lg={12}
            direction="column"
            alignItems="center"
            justify="center"
            container
          >
            <FooterTitle>Join Bookurtreat</FooterTitle>
            <Grid
              item
              container
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              gap={3}
            >
              <SocialLink href="https:web.whatsapp.com" target="_blank">
                <WhatsAppIcon sx={iconStyle} />
              </SocialLink>
              <SocialLink href="https:web.whatsapp.com" target="_blank">
                <FacebookIcon sx={iconStyle} />
              </SocialLink>
              <SocialLink href="https:web.whatsapp.com" target="_blank">
                <XIcon sx={iconStyle} />
              </SocialLink>
              <SocialLink href="https:web.whatsapp.com" target="_blank">
                <InstagramIcon sx={iconStyle} />
              </SocialLink>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Footer;
