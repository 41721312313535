import React, { useEffect, useRef } from 'react';
import { NotifyButton, Input } from './styles';
const EmailBox = ({ done, onClickEvent, t }) => {
  const inputRef = useRef();
  const [email, setEmail] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onClickEvent(email.toLowerCase());
  };

  useEffect(() => {
    if (done) {
      setEmail('');
      inputRef.current.value = '';
    }
  }, [done]);

  return (
    <form onSubmit={handleSubmit} className="common-form">
      <Input
        ref={inputRef}
        className="formInput"
        required
        type="email"
        id="email"
        name="email"
        autoComplete="off"
        onChange={(e) => setEmail(e.target.value)}
        placeholder={t('email')}
      />
      <NotifyButton type="submit"> {t('notify')}</NotifyButton>
    </form>
  );
};

export default EmailBox;
