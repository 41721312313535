import { Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FooterInfo = styled('p')(({ theme }) => ({
  fontSize: '32px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));
export const FormWrapper = styled(Grid)(({ theme }) => ({
  marginTop: '2rem !important',
  [theme.breakpoints.down('sm')]: {
    marginTop: '1rem !important',
  },
}));
export const FooterTitle = styled('h2')(({ theme }) => ({
  margin: '16px 0',
  fontSize: '6rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
  },
}));

export const SocialLink = styled(Link)(() => ({
  transition: 'all .3s',
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transform: 'translate(0, -2px)',
    scale: '1.02',
  },
}));
