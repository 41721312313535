import React from 'react';
import { v4 as uuidv5 } from 'uuid';
import butlogo from '../../assets/images/butlogo.png';
import { Grid } from '@mui/material';
import EmailBox from '../Shared/EmailBox';
import BUTDialog from '../Shared/BUTDialog';
import ReactGA from 'react-ga';
import { ENDPOINT_URL } from '../../utils/globalConfig';
import { LogDiv, SubTitle, Quotes, SignUp, Img } from './styles';
import dayjs from 'dayjs';
import { sendEmail } from '../../utils/emailjs';

import { ref, set, get } from 'firebase/database';
import { database } from '../../firebase/firebase.config';

function ContentAndNotify(props) {
  const [done, setDone] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState({
    title: '',
    subTitle: '',
    type: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = (email) => {
    const date = new Date();

    const dateFormatString =
      date.getFullYear().toString() +
      '-' +
      ((date.getMonth() + 1).toString().length === 2
        ? (date.getMonth() + 1).toString()
        : '0' + (date.getMonth() + 1).toString()) +
      '-' +
      (date.getDate().toString().length === 2
        ? date.getDate().toString()
        : '0' + date.getDate().toString()) +
      ' ' +
      (date.getHours().toString().length === 2
        ? date.getHours().toString()
        : '0' + date.getHours().toString()) +
      ':' +
      ((parseInt(date.getMinutes() / 5) * 5).toString().length === 2
        ? (parseInt(date.getMinutes() / 5) * 5).toString()
        : '0' + (parseInt(date.getMinutes() / 5) * 5).toString()) +
      ':00' +
      '.' +
      date.getMilliseconds();

    console.log({
      dateFormatString,
      dayjsFormat: dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
    });
    fetch(ENDPOINT_URL.LAUNCH_SIGNUP, {
      method: 'post',
      dataType: 'JSON',
      headers: {
        'Content-Type': 'application/json',
        ipAddress: props.ipAddress,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dateTime: dateFormatString,
      },
      body: JSON.stringify({ email: email }),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        // debugger
        if (data.status === 200 && data.success) {
          ReactGA.event({
            category: 'User',
            action: 'Fresh Signup',
            label: 'User Interaction',
          });

          setMsg({
            title: 'Thanks for signing up!',
            subTitle: data.message,
            type: 'success',
          });
        } else {
          ReactGA.event({
            category: 'User',
            action: 'Re-occuring Signup',
            label: 'User Interaction',
          });
          setMsg({
            title: 'HI',
            subTitle: data.error_message,
            type: 'error',
          });
        }
        handleClickOpen();
        setDone(true);
      })
      .catch((error) => {
        // debugger
        ReactGA.event({
          category: 'User',
          action: 'Error Signup',
          label: 'User Interaction',
        });
        setMsg({
          title: 'HI',
          subTitle: 'Error while processing data',
          type: 'error',
        });
        console.log(error, 'error while sending data');
      });
  };
  const generateUser = async (email) => {
    const userId = uuidv5();

    await set(ref(database, `users/${userId}`), {
      email,
      id: userId,
      emailVerified: false,
    });

    const response = await sendEmail({
      confirmation_link: `${process.env.REACT_APP_BASE_URL}/launch-notification-email-verify?id=${userId}`,
      facebook_link: 'facebook.com',
      whatsapp_link: 'web.whatsapp.com',
      instagram_link: 'instagram.com',
      x_link: 'x.com',
      to_email: email,
    });

    if (response.status === 200) {
      setMsg({
        title: 'Thanks for signing up!',
        subTitle:
          'You have successfully submitted your email to recieve bookurtreat notifications',
        type: 'success',
      });
    } else {
      setMsg({
        title: 'Signup error',
        subTitle:
          'An error occoured while trying sign you up to recieve bookurtreat notifications',
        type: 'error',
      });
    }
    setDone(true);
    setOpen(true);
  };
  const sendToEmail = async (email) => {
    const snapshot = await get(ref(database, 'user'));
    if (snapshot.exists()) {
      const users = Object.values(snapshot.val());
      const userExists = users.find(
        ({ email: userEmail }) => email === userEmail
      );
      //  Checks if the email exists
      if (userExists) {
        setMsg({
          title: 'Sign up error',
          subTitle:
            'This email has already been registered to recieve bookurtreat notifications',
          type: 'error',
        });
        setDone(true);
        setOpen(true);
        return;
      } else {
        generateUser(email);
      }
    } else {
      generateUser(email);
    }
  };

  return (
    <Grid item lg={6} md={9} xs={12}>
      <Grid container direction="column">
        <LogDiv>
          <Img src={butlogo} alt="icon" />
        </LogDiv>
        <SubTitle>{props.t('beauticians')}</SubTitle>
        <Quotes
          dangerouslySetInnerHTML={{ __html: props.t('acquire') }}
        ></Quotes>
        <SignUp>{props.t('signup')}</SignUp>

        <Grid item lg={12} className="email-grid">
          <EmailBox
            t={props.t}
            done={done}
            stretch={false}
            onClickEvent={sendToEmail}
            footer={false}
          />
        </Grid>
      </Grid>

      <BUTDialog msg={msg} handleClose={handleClose} open={open} />
    </Grid>
  );
}

export default ContentAndNotify;
