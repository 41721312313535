import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { BrowserRouter, NavLink, Route, Switch } from "react-router-dom";
import i18nData from './i18n/locales';
import Home from './components/Home';
import i18n from 'i18next';
// import { ThemeProvider } from 'styled-components';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { initReactI18next } from 'react-i18next';
// import { useTranslation, initReactI18next } from "react-i18next";

import { LanuchInvitationEmailVerify } from './components/verify/launch-notification-email-verify';
import { ExclusiveInvitationEmailVerify } from './components/verify/exclusive-invitation-email-verify';
import uk from './assets/images/uk.svg';
import './style/style.scss';

const selectedLanguage = 'en';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: i18nData,
    lng: selectedLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

const App = () => {
  const [selectedLang, setSelectedLang] = useState({
    img: uk,
    name: 'English(UK)',
  });
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/launch-notification-email-verify"
            component={LanuchInvitationEmailVerify}
          />
          <Route
            exact
            path="/exclusive-invitation-email-verify"
            component={ExclusiveInvitationEmailVerify}
          />
          <Route exact path="/">
            <Home
              selectedLang={selectedLang}
              setSelectedLang={setSelectedLang}
            />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
