import React from 'react';
import Dialog from '@mui/material/Dialog';
import { CheckCircle, Error } from '@mui/icons-material';
import { DialogContainer, DialogOk, MessageTitle } from './styles';

const BUTDialog = ({ handleClose, open, msg }) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContainer>
        <p className="success-icon">
          {msg.type === 'success' && <CheckCircle />}{' '}
        </p>
        <p className="error-icon">{msg.type === 'error' && <Error />} </p>

        <MessageTitle>{msg.title}</MessageTitle>
        <div style={{ padding: '10px' }} className="popup-text">
          {msg.subTitle}
        </div>
        <DialogOk onClick={handleClose} className="action-button">
          <b>Ok</b>
        </DialogOk>
      </DialogContainer>
    </Dialog>
  );
};

export default BUTDialog;
